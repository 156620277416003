<template>
  <div class="row">
    <div class="col-12 col-lg-7 c g">
        <br>
        <div class="card">
            <div class="card-body">
                <div class="col-12 table-responsive" id="ss">
                    <table class="table table-hover table-bordered">
                        <tbody>
                            <tr>
                                <td class="g">
                                    الاسم
                                </td>
                                <td class="g">
                                    {{ name }}
                                </td>
                            </tr>
                            <tr>
                                <td class="g">
                                    العنوان
                                </td>
                                <td class="g">
                                    {{ title }}
                                </td>
                            </tr>
                            <tr>
                                <td class="g">
                                    التفاصيل
                                </td>
                                <td class="g" v-html="details"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 text-center">
                    <div class="alert alert-warning text-center g" v-if="!students.length">
                        قم باستيراد بيانات الطلاب من مدرستي للاداة لتتمكن من الارسال.
                        <br>
                        <a :href="`https://schools.madrasati.sa/SchoolManagment/Actions/Index/${school_id}#openmenu-madrasati/import-students-from-madrasati`" target="_blank"><u>استيراد بيانات الطلاب الان</u></a>
                    </div>
                    <button class="btn btn-primary" @click="send()" v-if="students.length">
                        <i class="fa fa-paper-plane"></i>
                        ارسال الان للطلاب
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-7 c g">
        <br>
        <div class="card">
            <div class="card-body">
                <div class="g">
                    <b-dropdown
                    id="dropdown-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    text="📥 طباعة / تصدير"
                    variant="primary"
                    >
                    <b-dropdown-item @click="printDiv()">
                        <i class="fa fa-print"></i>
                        طباعة التقرير
                    </b-dropdown-item><hr>
                    <b-dropdown-item @click="exportToExcel()">
                        <i class="fa fa-download"></i>
                        تصدير التقرير اكسل
                    </b-dropdown-item>
                    </b-dropdown>
                    &nbsp;
                    <span id="gg" v-if="!disabled">
                        <b-dropdown
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        text="عرض حالة معينة"
                        variant="success"
                        >
                        <b-dropdown-item @click="status(true)">
                            <i class="fa fa-check"></i>
                            من قام بالحل
                        </b-dropdown-item><hr>
                        <b-dropdown-item @click="status(false)">
                            <i class="fa fa-times"></i>
                            من لم يقم بالحل
                        </b-dropdown-item>
                        </b-dropdown>
                    </span>
                </div>
                <div class="col-12 table-responsive" id="headerTable">
                    <div id="s" class="dnone"></div>
                    <table class="table table-hover table-bordered">
                        <thead>
                            <th>
                                الطالب
                            </th>
                            <th>
                                الفصل
                            </th>
                            <th>
                                الدرجة
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="student in arr" :key="student.name">
                                <td>
                                    {{ student.name }}
                                </td>
                                <td>
                                    {{ student.classroom }}
                                </td>
                                <td>
                                    {{ student.degree }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-students-ghiab-manual" size="lg" title="ارسال رسائل لاولياء الامور الغائبين" hide-footer>
        <div class="col-12 text-center g">
            <div class="alert alert-success text-center g" v-if="view_archive">
                <img src="/files/done.gif" style="border-radius: 50%">
                <br>
                <br>
                تم استلام الرسائل بنجاح وجاري ارسالها الآن٫<br>
                يمكنك متابعة الارسال <a :href="`/archive?school_id=${school_id}`" target="_blank"><strong><u>من هنا</u></strong></a>
            </div>
            <template v-if="!loading && !view_archive">
                <button class="btn btn-lg btn-primary" v-if="selected == 'sms' || selected == 'whatsapp-api'" @click="sendNow(selected)">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
                <div class="col-12 text-center alert alert-success" v-if="selected == 'whatsapp'" id='remove-me-wa'>
                    <h4>
                        لتتمكن من ارسائل رسائل الواتسآب، يجب عليك تحميل أداة الخدمات الاضافية من سوق كروم.
                        <br><br>
                        <a href="https://bit.ly/3yAC50a" target="_blank" class="btn btn-success">
                            <i class="fa fa-download"></i>
                            إضغط هنا لتثبيت الاداة
                        </a>
                        <br>
                        وبعد تثبيتها قم بإعادة تحديث هذه الصفحة.
                    </h4>
                </div>
                <button class="btn btn-lg btn-primary" v-if="selected == 'whatsapp'" @click="sendNow(selected)" id="brmja-send">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
            </template>
            <template v-if="loading && !view_archive">
                <button class="btn btn-lg btn-primary disabled">
                    جاري الإرسال...
                </button>
            </template>
        </div>
        <div class="form-group">
          <label for="">محتوى الرسالة</label>
          <textarea class="form-control" @keyup="messageChanged()" @change="messageChanged()" v-model="message" rows="5"></textarea>
        </div>
        <div class="col-12 table-responsive">
            <table class="table table-sm table-hover" id="brmja-messages-table">
                <thead>
                    <th>
                        الجوال
                    </th>
                    <th>
                        الطالب
                    </th>
                    <th>
                        الرسالة
                    </th>
                </thead>
                <tbody id="students-messages">
                    <template v-for="(student, c) in arr">
                      <tr :key="c">
                          <td>{{ student.phone }}</td>
                          <td>{{ student.name }}</td>
                          <td><textarea class='form-control msg' readonly v-model="student.message"></textarea></td>
                      </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </b-modal>
    <button
      class="dnone"
      id="students-ghiab-manual"
      @click="sendModal()"
      v-b-modal.modal-students-ghiab-manual
    ></button>

  </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            arr: [],
            students: [],
            html: "",
            type: "",
            name: "",
            title: "",
            details: "",
            disabled: false,
            school_id: window.location.href.split("school_id=")[1].split("&")[0],
            selected: "",
            loading: false,
            message: `المكرم ولي أمر الطالب :
{name}، نشعركم أن ابنكم لديه ${this.type == 'exams' ? 'اختبار' : 'واجب'} جديد في المنصة
بعنوان {title}
الدرجة: {degree}
رابط المنصة : https://schools.madrasati.sa/`,
            view_archive: false
        }
    },
    created(){
      var g = this;
      var school_id = g.school_id
      setTimeout(() => {
        $("body").append(`
          <textarea class='get-local-storage' name='m_students_${school_id}'></textarea>
        `)
        var i1 = setInterval(() => {
            if($(`.get-local-storage-value[name='m_students_${school_id}']`).length){
              clearInterval(i1)
              if($(`.get-local-storage-value[name='m_students_${school_id}']`).val() && $(`.get-local-storage-value[name='m_students_${school_id}']`).val() != "undefined"){
                g.students = JSON.parse($(`.get-local-storage-value[name='m_students_${school_id}']`).val())
              }
              $("body").append(`
                <textarea class='get-local-storage' name='html'></textarea>
                `)
                var i2 = setInterval(() => {
                    if($(`.get-local-storage-value[name='html']`).length){
                    clearInterval(i2)
                    g.html = $(`.get-local-storage-value[name='html']`).val()
                     $("body").append(`
                    <textarea class='get-local-storage' name='name'></textarea>
                    `)
                    var i3 = setInterval(() => {
                        if($(`.get-local-storage-value[name='name']`).length){
                        clearInterval(i3)
                        g.name = $(`.get-local-storage-value[name='name']`).val()
                         $("body").append(`
                        <textarea class='get-local-storage' name='title'></textarea>
                        `)
                        var i4 = setInterval(() => {
                            if($(`.get-local-storage-value[name='title']`).length){
                            clearInterval(i4)
                            g.title = $(`.get-local-storage-value[name='title']`).val()
                             $("body").append(`
                            <textarea class='get-local-storage' name='details'></textarea>
                            `)
                            var i5 = setInterval(() => {
                                if($(`.get-local-storage-value[name='details']`).length){
                                clearInterval(i5)
                                g.details = $(`.get-local-storage-value[name='details']`).val()
                                $(g.html).find("tr").each(function () {
                                if ($(this).find('.decimal').length) {
                                    var degree = $(this).find('.decimal').first().val()
                                } else {
                                    var degree = $(this)?.find("small:contains('الدرجة:')")?.text()?.split("الدرجة:")[1]
                                }
                                g.arr.push({
                                    name: $(this).find("td:nth-child(1) > span").text(),
                                    classroom: $(this).find("td:nth-child(1) > small:nth-child(5) > span.smalldata").text(),
                                    degree: degree,
                                    message: "",
                                    phone: "",
                                    number: $(this).find("td:nth-child(1) > small:nth-child(3) > span.smalldata").text()?.trim()
                                })
                                 })
                                }
                            }, 10);
                            }
                        }, 10);
                        }
                    }, 10);
                    }
                }, 10);
            }
        }, 10);
      }, 500);
    },
    methods: {
        sendModal(){
          var g =this;
          if(localStorage.getItem('send')){
              g.selected = JSON.parse(localStorage.getItem('send')).selected
          }
          g.messageChanged()
        },
        refresh(){
          this.arr = JSON.parse(JSON.stringify(this.arr))
        },
        printDiv() {
            document.getElementById("s").innerHTML = document.getElementById("ss").innerHTML
            var divToPrint=document.getElementById("headerTable");
            var newWin= window.open("");
            newWin.document.write(divToPrint.outerHTML + `<style>
                body{
                    direction: rtl;
                    text-align: right
                }
                thead{
                    background: #eee;
                }
                table, td, th {
                border: 1px solid;
                white-space: nowrap;
                }
                tr:nth-child(even) {
                    background-color: #f2f2f2;
                }
                table {
                width: 100%;
                border-collapse: collapse;
                }
                button{
                    background: none;
                    border:none
                }
                </style>`);
            newWin.document.close();
            newWin.print();
        },
        exportToExcel() {
            var location = 'data:application/vnd.ms-excel;base64,';
            var excelTemplate = '<html> ' +
                '<head> ' +
                '<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/> ' +
                '</head> ' +
                '<body> ' +
                document.getElementById("headerTable").outerHTML +
                '</body> ' +
                '</html>'
            window.location.href = location + btoa(unescape(encodeURIComponent((excelTemplate))));
        },
        status(s){
            var g = this, v = [];
            g.arr.forEach(function(a){
                if(!s){
                    if(!a.degree || a.degree == undefined || a.degree == 'undefined' || a?.degree?.includes('لم')){
                        v.push(a)
                    }
                }else{
                    if(a?.degree?.includes('.') || a?.degree?.includes('0')){
                        v.push(a)
                    }
                }
            })
            g.arr = v
            g.disabled = true
        },
        send(){
            $("#c-subscribe-ended").click()
            $("#after-send-settings-click").val("#students-ghiab-manual")
        },
        sendModal(){
          var g =this;
          if(localStorage.getItem('send')){
              g.selected = JSON.parse(localStorage.getItem('send')).selected
          }
          g.messageChanged()
        },
        messageChanged(){
          var g = this;
          g.arr = g.arr.map(function(student){
            student.message = g.message.replace("{name}", student?.name).replace("{title}", g.name).replace("{degree}", student?.degree ?? 'لم يتم الحل')
            g.students.forEach(function(a){
                if(student.number == a.number){
                    student.phone = a.phone
                }
            })
            return student;
          })
          g.refresh()
        },
        sendNow(method){
            var g = this;
            var c = []
            g.arr.forEach(function(s){
                c.push({
                    phone: s.phone,
                    message: s.message
                })
            })
            g.loading = true
            $.post(api + '/api/messages/send', {
                school_id: g.school_id,
                arr: JSON.stringify(c),
                method: method,
                settings: localStorage.getItem('send'),
                type: 'students'
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.view_archive = true
                }else{
                    alert(response.response)
                }
            })
            .fail(function(){
                g.loading = false
                alert('حدث خطأ')
            })
        },
    }
}
</script>

<style>
#date2{
    display: none;
}
.b-dropdown.show{
  width: 300px
}
</style>